import { ScreenshareModePicker } from "@/app/app-components/ScreenshareModePicker/ScreenshareModePicker";
import { MGButton } from "@/components/buttons";
import { MGDaysPickerField } from "@/components/controls";
import {
  MGAutocomplete,
  MGDropdownField,
  MGDropdownItem,
  MGInputListField,
  MGTextAreaField,
  MGTextboxField,
} from "@/components/inputs";
import { MGColorPickerField } from "@/components/inputs/MGColorPicker";
import { MGFlexBox, MGFlexBoxSpacer } from "@/components/layout";
import { MGModal, MGModalBody, MGModalFooter, MGModalHeader } from "@/components/modals";
import { MGTimePickerField } from "@/components/pickers";
import { MGProgressSpinner } from "@/components/progress";
import { MGSpacing } from "@/components/spacing";
import { MGHeading3, MGHelperText, MGLabel } from "@/components/typography";

import { Form, Formik } from "formik";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import * as yup from "yup";

export const ConfigureClassForm = (props) => {
  const {
    initialValues,
    onSubmit,
    classDurations,
    teachers,
    isShowing,
    loading,
    loadingViewClass,
    onCancel,
    formHeading,
    getFilteredTeachers,
    debounceSearch,
  } = props;

  const { t } = useTranslation();

  const minuteToMillisecondsFactor = 1000 * 60;

  const submissionHandler = (values) => {
    let teacherIds = [];

    if (values.teachers && values.teachers.length > 0) {
      teachers.map((teacher) => {
        if (values.teachers.includes(teacher.full_name)) {
          teacherIds.push(teacher.id);
        }
      });
    }
    values.teacher_ids = teacherIds;

    //values.teachers does not exist on the payload required for addclass mutation.
    //It gets added to the form, and then removed before being returned to pageState.js
    let submissionValues = Object.assign({}, values);
    delete submissionValues.teachers;
    onSubmit(submissionValues);
  };

  const validationSchema = yup.object({
    name: yup.string().min(3).required(t("Required")),
    duration: yup.number().required(t("Required")),
    screen_share_enabled: yup.string().required(t("Required")),
    schedules: yup.object({
      start_time: yup.date(),
      end_time: yup.date().min(yup.ref("start_time"), t("End time must be later than start time")),
    }),
    description: yup.string().required(t("Required")),
  });

  return (
    <MGModal isShowing={isShowing}>
      {loadingViewClass && <MGProgressSpinner isLarge={true} />}
      {!loadingViewClass && (
        <Formik initialValues={initialValues} onSubmit={submissionHandler} validationSchema={validationSchema}>
          {({ dirty, isValid, submitForm, values, setFieldValue, setFieldTouched }) => {
            let filteredTeachers = getFilteredTeachers(values);

            const [teacherSearchText, setTeacherSearchText] = useState("");

            const addItemToList = (item) => {
              let text = item;
              if (!text) text = teacherSearchText;

              if (text) {
                let newUuids = values?.teachers ?? [];
                filteredTeachers?.map((teacher) => {
                  if (teacher?.full_name === text) {
                    newUuids.push(teacher);
                    setFieldValue("teachers", newUuids);
                    setFieldTouched("teachers", true);
                    setTimeout(() => {
                      debounceSearch("");
                      setTeacherSearchText("");
                    }, 100);
                  }
                });
              }
            };

            const onDurationChangeHandler = (duration) => {
              setFieldValue(
                "schedules.end_time",
                new Date(
                  Math.round(new Date(values.schedules.start_time).getTime() + duration * minuteToMillisecondsFactor)
                )
              );
            };

            const onStartTimeChangeHandler = (startTime) => {
              setFieldValue(
                "schedules.end_time",
                new Date(Math.round(new Date(startTime).getTime() + values.duration * minuteToMillisecondsFactor))
              );
            };

            return (
              <>
                <MGModalHeader>
                  <MGHeading3>{formHeading}</MGHeading3>
                </MGModalHeader>
                <MGModalBody>
                  <Form noValidate autoComplete={"off"}>
                    <MGLabel htmlFor="name">{t("Class Name")}</MGLabel>
                    <MGTextboxField name="name" placeholder={t("Enter Class Name")} />
                    <MGLabel>{t("Teachers")}</MGLabel>
                    <MGAutocomplete
                      value={teacherSearchText}
                      onChange={(text) => {
                        setTeacherSearchText(text);
                        debounceSearch(text);
                      }}
                      placeholder={loading ? t("Loading...") : t("Search for teacher")}
                      suggestions={filteredTeachers?.map((teacher) => {
                        return teacher?.full_name;
                      })}
                      actionButtonIcon={"add"}
                      onActionButtonClick={addItemToList}
                      enterKeyFiresAction={true}
                      onItemSelected={addItemToList}
                    />

                    <br />
                    <MGInputListField
                      name={"teachers"}
                      displayExpr={(teacher) => {
                        return teacher?.full_name;
                      }}
                      onItemRemoved={(item) => {
                        let newTeachers = [];
                        values?.teachers?.map((teacher) => {
                          if (teacher?.id !== item.id) {
                            newTeachers.push(teacher);
                          }
                          setFieldValue("teachers", newTeachers);
                          setFieldTouched("teachers", true);
                        });
                      }}
                    />
                    <MGLabel>{t("Set duration for class")}</MGLabel>
                    <MGDropdownField
                      name="duration"
                      placeholder={t("Class duration")}
                      onChange={onDurationChangeHandler}
                    >
                      {classDurations &&
                        classDurations.map((duration) => (
                          <MGDropdownItem key={duration.value} value={duration.value}>
                            {duration.content}
                          </MGDropdownItem>
                        ))}
                    </MGDropdownField>
                    <MGLabel>{t("Screen share mode")}</MGLabel>
                    <ScreenshareModePicker name={"screen_share_enabled"} placeholder={t("Select screen share mode")} />
                    <MGLabel>{t("Set schedule")}</MGLabel>
                    <MGFlexBox justify="start">
                      <div>
                        <MGHelperText>{t("Start time")}</MGHelperText>
                        <MGTimePickerField name="schedules.start_time" onChange={onStartTimeChangeHandler} />
                      </div>
                      <MGSpacing size="md" />
                      <div>
                        <MGHelperText>{t("End time")}</MGHelperText>
                        <MGTimePickerField name="schedules.end_time" />
                      </div>
                    </MGFlexBox>
                    <MGFlexBox>
                      <div>
                        <MGLabel>{t("Select days of the week")}</MGLabel>
                        <br />
                        <MGDaysPickerField name="schedules.weekdays" />
                      </div>
                      <MGSpacing size={"xl"} />
                      <MGLabel htmlFor="class_color">{t("Class color")}</MGLabel>
                      <MGSpacing size={"md"} />
                      <div>
                        <MGColorPickerField name="class_color" inline={true} />
                      </div>
                      <MGFlexBoxSpacer />
                    </MGFlexBox>
                    <MGLabel>{t("Description")}</MGLabel>
                    <MGTextAreaField name="description" width={"100%"} height={"120px"} />
                  </Form>
                </MGModalBody>
                <MGModalFooter>
                  <center>
                    <MGButton variant="secondary" onClick={onCancel}>
                      {t("Cancel")}
                    </MGButton>
                    <MGSpacing size={"md"} />
                    <MGButton onClick={submitForm} variant="primary" isLoading={loading} disabled={!dirty || !isValid}>
                      {t("Save")}
                    </MGButton>
                  </center>
                </MGModalFooter>
              </>
            );
          }}
        </Formik>
      )}
    </MGModal>
  );
};

export default ConfigureClassForm;
