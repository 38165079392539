import { Subject } from "rxjs";
import { WebRTCCache } from "./web-rtc-cache";
import Config from "@/config";
import { logger } from "@/app/services/logging/Logger";

export class WebRTCConnection {
  /**
   *
   * @param {object} device
   * @param {string} classId
   * @param {ScreenShareSocket} socket
   * @param {boolean} isLegacy
   */
  constructor(device, classId, socket, onIceCandidateCallback, isLegacy = false) {
    logger.debug("new WebRTCConnection", WebRTCConnection._configuration);
    this._device = device;
    this._classId = classId;
    this.socket = socket;
    this.onIceCandidateCallback = onIceCandidateCallback;
    let configuration = { ...WebRTCConnection._configuration };
    if (isLegacy) {
      configuration.iceServers[0].urls = [Config.STUN_TURN_LEGACY];
    }
    this.peerConnection = new RTCPeerConnection(configuration);
    this.state = new WebRTCConnectionState(this);
  }
  onIceCandidateCallback;
  /**
   * @var {object} _device
   */
  _device;

  /**
   * @var {string} _classId
   */
  _classId;

  /**
   * @var {object[]} iceCandidates
   */
  iceCandidates = [];

  /**
   * @var {ScreenShareSocket} socket
   */
  socket;

  /**
   * @var {RTCPeerConnection} peerConnection
   */
  peerConnection;

  /**
   * @var {object} mediaStream
   */
  mediaStream;

  /**
   * @var {WebRTCConnectionState} state
   */
  state;

  /**
   * @var {boolean} permissionGranted
   * used to keep current permision status in cache
   */
  permissionGranted;

  /**
   * @var {RTCIceCandidate} localProtocol
   */
  localProtocol;

  /**
   * @var {RTCIceCandidate} remoteProtocol
   */
  remoteProtocol;

  /**
   * @var {RTCConfiguration} _configuration
   */
  static _configuration = {
    iceServers: [
      {
        urls: [Config.STUN_TURN],
        credential: "mgstun",
        username: "mgstun",
      },
    ],
    iceTransportPolicy: Config.ICE_TRANSPORT_POLICY ?? "all",
    advanced: [
      { googHighStartBitrate: { exact: 0 } },
      { googPayloadPadding: { exact: true } },
      { googScreencastMinBitrate: { exact: 100 } },
      { googCpuOveruseDetection: { exact: true } },
      { googCpuOveruseEncodeUsage: { exact: true } },
      { googCpuUnderuseThreshold: { exact: 55 } },
      { googCpuOveruseThreshold: { exact: 85 } },
      { enableDscp: { exact: true } },
    ],
  };

  /**
   * @var {object} device
   */
  get device() {
    return this._device;
  }

  dispose() {
    logger.debug("Disposing RTC connection");
    this.deregisterEvents();
    this.peerConnection.close();
    this.iceCandidates = [];
    this.state.stopChecking();
    WebRTCCache.removeConnection(this.device.device_signature);
  }

  registerEvents() {
    this.deregisterEvents();
    logger.debug("registering events");
    this.peerConnection.onicecandidate = (event) => this.onIceCandidate(event);
    this.peerConnection.onconnectionstatechange = (event) => this.onConnectionStateChange(event);
    this.peerConnection.oniceconnectionstatechange = (event) => this.onIceConnectionStateChange(event);
    this.peerConnection.onicegatheringstatechange = (event) => this.onIceGatheringStateChange(event);
    this.peerConnection.onsignalingstatechange = (event) => this.onSignalingStateChange(event);
    this.peerConnection.onnegotiationneeded = (event) => this.onNegotiationNeeded(event);
    this.peerConnection.ontrack = (event) => this.onRemoteStream(event);
    var senders = this.peerConnection.getSenders();
    logger.debug("senders", senders);
    if (senders && senders.length > 0 && senders[0].transport) {
      var iceTransport = senders[0].transport.iceTransport;
      if (iceTransport) {
        iceTransport.onselectedcandidatepairchange = (event) => {
          var pair = iceTransport.getSelectedCandidatePair();
          logger.debug("onSelectedCandidatePairChange", event, pair);
          this.localProtocol = pair.local;
          this.remoteProtocol = pair.remote;
          this.onConnectionChange.next();
        };
      }
    }
  }

  deregisterEvents() {
    logger.debug("deregistering events");
    this.peerConnection.onicecandidate = null;
    this.peerConnection.onconnectionstatechange = null;
    this.peerConnection.oniceconnectionstatechange = null;
    this.peerConnection.onicegatheringstatechange = null;
    this.peerConnection.onsignalingstatechange = null;
    this.peerConnection.onnegotiationneeded = null;
    this.peerConnection.ontrack = null;
  }

  /**
   *
   * @param {object} iceCandidate
   */
  async addIceCandidate(iceCandidate) {
    if (
      !this.peerConnection ||
      !(this.peerConnection.remoteDescription && this.peerConnection.remoteDescription.type)
    ) {
      // if (this.iceCandidates.length > 10) {
      //   this.iceCandidates = [];
      // }
      logger.debug("storing ice Candidates");
      this.iceCandidates.push(iceCandidate);
    } else {
      logger.debug("adding iceCandidates");
      await this.peerConnection.addIceCandidate(iceCandidate);
    }
  }

  /**
   *
   * @returns WebRTCConnection | undefined
   */
  getConnection() {
    // if not connected return null so that the connection can be recreated
    if (this.peerConnection.connectionState !== "connected" && this.peerConnection.iceConnectionState !== "connected") {
      this.state?.onStatusChange.next("ended");
      this.dispose();
      return undefined;
    }
    return this;
  }

  /**
   *
   * @param {object} offer
   */
  async createAnswer(offer) {
    this.registerEvents();
    var remoteDescription = new RTCSessionDescription(offer);

    if (this.peerConnection.signalingState !== "stable") {
      logger.debug("Singaling state not stable, triggering rollback");
      Promise.all([
        await this.peerConnection.setLocalDescription({ type: "rollback" }),
        await this.peerConnection.setRemoteDescription(remoteDescription),
      ]);
      return;
    } else {
      logger.debug("Setting remote description");
      await this.peerConnection.setRemoteDescription(remoteDescription);
    }

    logger.debug("Creating and sending answer to caller");

    await this.peerConnection.setLocalDescription(await this.peerConnection.createAnswer());

    logger.debug("create answer - ice candidates ", this.iceCandidates);
    if (this.iceCandidates && this.iceCandidates.length) {
      /**
       * @param {object} candidate
       */
      this.iceCandidates.forEach((candidate) => {
        this.peerConnection.addIceCandidate(candidate);
      });
    }

    this.iceCandidates = [];
    this.socket.socket.emit(this.socket.SCREEN_SHARE_SEND, {
      from: this.socket.socket.id,
      to: this._device.device_signature,
      class: this._classId,
      payload: {
        module: "screen",
        action: "answer",
        type: "answer",
        answer: {
          type: this.peerConnection.localDescription?.type,
          sdp: this.peerConnection.localDescription?.sdp,
        },
        has_more: false,
        chunk_number: 0,
        from: this.socket.socket.id,
        to: this._device.device_signature,
      },
    });
    return {
      // sender: this._device.device_signature,
      answer: {
        type: this.peerConnection.localDescription?.type,
        sdp: this.peerConnection.localDescription?.sdp,
      },
      has_more: false,
      chunk_number: 0,
    };
  }

  /**
   *
   * @param {RTCPeerConnectionIceEvent} event
   */
  onIceCandidate(event) {
    logger.debug("onIceCandidate", event);
    if (event.candidate == null) {
      return;
    }
    let data = {
      iceCandidate: event.candidate,
      module: "screen",
      type: "candidate",
      to: this._device.device_signature,
      from: this.socket.socket.id,
    };
    if (this.onIceCandidateCallback) {
      this.onIceCandidateCallback(event);
    }
    this.socket.socket.emit(this.socket.SCREEN_SHARE_SEND, {
      to: this._device.device_signature,
      from: this.socket.socket.id,
      payload: data,
      class: this._classId,
    });
  }

  /**
   *
   * @param {Event} event
   */
  onIceConnectionStateChange(event) {
    /**e
     * @var {RTCPeerConnection} peerConnection
     */
    let peerConnection = event.target;
    logger.debug("Ice Connection State Change:", peerConnection.iceConnectionState);
    this.onConnectionChange.next();
    if (
      peerConnection &&
      (peerConnection.connectionState === "failed" || peerConnection.connectionState === "disconnected")
    ) {
      this.state?.onStatusChange?.next("ended");
      // this.dispose();
    }
  }

  /**
   * @var {Subject} onConnectionStateChangeSubject
   */
  onConnectionChange = new Subject();

  /**
   *
   * @returns {Subject} connectionStateChange
   */
  connectionStateChange() {
    return this.onConnectionChange.asObservable();
  }
  /**
   *
   * @param {Event} event
   */
  onConnectionStateChange(event) {
    /**
     * @var {RTCPeerConnection} peerConnection
     */
    let peerConnection = event.target;
    this.connectionState = peerConnection.connectionState;
    logger.debug("Connection State Change", peerConnection.connectionState);
    this.onConnectionChange.next();
    if (peerConnection.connectionState !== "connecting" && peerConnection.connectionState !== "connected") {
      this.state?.onStatusChange?.next("ended");
      this.dispose();
    }
  }

  /**
   * @var {string} connectionState
   */
  connectionState;

  /**
   *
   * @param {Event} event
   */
  onIceGatheringStateChange(event) {
    logger.debug("Ice Gathering State Change", event);
  }

  /**
   *
   * @param {Event} event
   */
  onSignalingStateChange(event) {
    /**
     * @var {RTCPeerConnection} peerConnection
     */
    let peerConnection = event.target;
    logger.debug("Signaling State Change:", peerConnection.signalingState);
    if (peerConnection.signalingState == "closed") {
      this.state?.onStatusChange?.next("ended");
      this.dispose();
    }
  }

  /**
   *
   * @param {Event} event
   * @returns
   */
  async onNegotiationNeeded(event) {
    logger.debug("Negotiation Needed", event);
    try {
      logger.debug("Createing offer...");
      const offer = await this.peerConnection.createOffer();

      if (this.peerConnection.signalingState != "stable") {
        logger.debug("Connection not stable!");
        return;
      }

      logger.debug("Set local description");
      await this.peerConnection.setLocalDescription(offer);

      logger.debug("Sending offer to remote peer");
      this.socket.socket.emit(this.socket.SCREEN_SHARE_SEND, {
        from: this.socket.socket.id,
        to: this._device.device_signature,
        class: this._classId,
        payload: {
          from: this.socket.socket.id,
          to: this._device.device_signature,
          module: "screen",
          action: "answer",
          type: "answer",
          answer: {
            type: this.peerConnection.localDescription?.type,
            sdp: this.peerConnection.localDescription?.sdp,
          },
          has_more: false,
          chunk_number: 0,
        },
      });
    } catch (error) {
      logger.error("Negotiation Error", error);
    }
  }

  /**
   *
   * @param {RTCTrackEvent} event
   */
  onRemoteStream(event) {
    logger.debug("Got remote stream", event);
    let pc = this.peerConnection;
    let streams = pc.getRemoteStreams();
    if (streams && streams.length) {
      event = streams[0];
      this.mediaStream = event;
    }
  }

  /**
   * @var {Subject} onStateChange
   */
  onStateChange = new Subject();
  stateChanged() {
    return this.onStateChange.asObservable();
  }
}

class WebRTCConnectionState {
  /**
   *
   * @param {WebRTCConnection} rtcConnection
   */
  constructor(rtcConnection) {
    this.rtcConnection = rtcConnection;
    this.checkStream();
  }

  /**
   * @var {Object} actual
   * This holds the reducer state for the connection
   */
  actual;

  /**
   * @var {WebRTCConnection} rtcConnection
   */
  rtcConnection;

  /**
   * @var {number} lastBytesReceived
   */
  lastBytesReceived = 0;

  /**
   * @returns {string} 'streaming' | 'ended'
   */
  onStatusChange = new Subject();

  /**
   * @var {string} streamState
   */
  streamState = "ended";
  /**
   * @var isStreaming
   */
  get isStreaming() {
    return this.streamState == "streaming";
  }

  /**
   * @var {NodeJS.Timer} streamInterval
   */
  streamInterval;
  // Connection States
  /**
   * @returns boolean
   */
  get connecting() {
    return this.rtcConnection.peerConnection.connectionState == "connecting";
  }

  /**
   * @returns boolean
   */
  get connected() {
    return this.rtcConnection.peerConnection.connectionState == "connected";
  }
  /**
   * @returns boolean
   */
  get closed() {
    return this.rtcConnection.peerConnection.connectionState == "closed";
  }
  /**
   * @returns boolean
   */
  get disconnected() {
    return this.rtcConnection.peerConnection.connectionState == "disconnected";
  }
  /**
   * @returns boolean
   */
  get failed() {
    return this.rtcConnection.peerConnection.connectionState == "failed";
  }
  /**
   * @returns boolean
   */
  get new() {
    return this.rtcConnection.peerConnection.connectionState == "new";
  }

  // ICE States
  /**
   * @returns boolean
   */
  get iceChecking() {
    return this.rtcConnection.peerConnection.iceConnectionState == "checking";
  }
  /**
   * @returns boolean
   */
  get iceClosed() {
    return this.rtcConnection.peerConnection.iceConnectionState == "closed";
  }
  /**
   * @returns boolean
   */
  get iceCompleted() {
    return this.rtcConnection.peerConnection.iceConnectionState == "completed";
  }
  /**
   * @returns boolean
   */
  get iceConnected() {
    return this.rtcConnection.peerConnection.iceConnectionState == "connected";
  }
  /**
   * @returns boolean
   */
  get iceDisconnected() {
    return this.rtcConnection.peerConnection.iceConnectionState == "disconnected";
  }
  /**
   * @returns boolean
   */
  get iceFailed() {
    return this.rtcConnection.peerConnection.iceConnectionState == "failed";
  }
  /**
   * @returns boolean
   */
  get iceNew() {
    return this.rtcConnection.peerConnection.iceConnectionState == "new";
  }

  streamStatusChanged() {
    return this.onStatusChange.asObservable();
  }

  stopChecking() {
    if (this.streamInterval) {
      clearInterval(this.streamInterval);
    }
    this.onStatusChange.complete();
    this.streamState = "ended";
  }

  checkStream() {
    logger.debug("===> Check stream");
    if (!this.streamInterval) {
      var sameBytesCount = 0;
      logger.debug("===> Create interval");
      this.streamInterval = setInterval(() => {
        this.rtcConnection.peerConnection
          .getStats(this.rtcConnection.mediaStream?.getVideoTracks()[0])
          .then((stats) => {
            stats.forEach((report) => {
              if (report["type"] && report["kind"]) {
                if (report["type"] === "inbound-rtp" && report["kind"] === "video") {
                  Object.keys(report).forEach((statName) => {
                    if (statName == "bytesReceived") {
                      let bytesReceived = report[statName];

                      if (this.lastBytesReceived == bytesReceived) {
                        if (sameBytesCount > 2) {
                          this.streamState = "ended";
                          this.onStatusChange.next("ended");
                        } else {
                          this.streamState = "streaming";
                        }
                      } else {
                        this.sameBytesCount = 0;
                        this.streamState = "streaming";
                        this.onStatusChange.next("streaming");
                      }
                      this.lastBytesReceived = bytesReceived;
                    }
                  });
                }
              }
            });
          });
      }, 1000);
    }
  }
}
