import { gql } from "@apollo/client";
import { PageInfoFragments } from "../fragments/page_info_fragment";

export const QUERY_CLASS_LIST = gql`
  query classes($page: Int, $limit: Int, $q: String, $filter: ClassListFilter) {
    classes(limit: $limit, page: $page, q: $q, filter: $filter) {
      __typename
      ... on ClassConnection {
        edges {
          node {
            id
            name
            screen_share_enabled
            duration
            last_session {
              duration
            }
            schedules {
              start_time
              end_time
              weekdays
            }
            active_session {
              id
            }
            teachers {
              id
              firstname
              lastname
            }
            meta_data {
              color
            }
          }
        }
        pageInfo {
          ...PageInfoAllParts
        }
      }
      ... on NotQuiteSureProblem {
        message
      }
      ... on Problem {
        message
      }
    }
  }
  ${PageInfoFragments.allParts}
`;

export const GET_MODES_FOR_CLASS = gql`
  query classModesByClass($classId: ID!) {
    classModesByClass(classId: $classId, limit: 10) {
      __typename
      ... on ClassModeConnection {
        edges {
          node {
            id
            name
          }
        }
      }
    }
  }
`;

//The query below is a HACK - it is only being used to cater for class-mode-switching.
//Currently, you need the class's CURRENT mode to be able to switch to another mode using mutations (start, stop & switch class mode)
//This is a minified version of the class() query only used to retrieve the current class mode before making the switch
//It should be removed as soon as the API has a SINGLE endpoint to switch a class mode
export const VIEW_CLASS__GET_CURRENT_MODE = gql`
  query class($classId: ID!) {
    class(id: $classId) {
      __typename
      ... on Class {
        id
        active_class_mode_session {
          id
          class_mode {
            id
            name
          }
        }
      }
      ... on RecordNotFoundProblem {
        message
      }
      ... on NotQuiteSureProblem {
        message
      }
      ... on Problem {
        message
      }
    }
  }
`;

export const PLAN_CLASS = gql`
  query class($classId: ID!) {
    class(id: $classId) {
      __typename
      ... on Class {
        id
        name
        duration
        screen_share_enabled
        screen_share_type
        description
        profile_uuid
        base_profile_uuid
        meta_data {
          color
          add_student_method
          add_student_method_locked
        }
        active_session {
          id
          has_ended
        }
      }
      ... on RecordNotFoundProblem {
        message
      }
      ... on NotQuiteSureProblem {
        message
      }
      ... on Problem {
        message
      }
    }
  }
`;

export const VIEW_CLASS = gql`
  query class($classId: ID!) {
    class(id: $classId) {
      __typename
      ... on Class {
        id
        name
        duration
        description
        base_profile_uuid
        profile_uuid
        screen_share_enabled
        screen_share_type
        force_join
        teacher {
          id
          username
          firstname
          lastname
        }
        teachers {
          id
          firstname
          lastname
          username
        }
        schedules {
          start_time
          end_time
          weekdays
        }
        active_session {
          id
          has_ended
          duration
          pin
          home_url
          created_at
          updated_at
          session_end_time
          allow_raise_hand
        }
        active_class_mode_session {
          id
          class_mode {
            id
            name
          }
        }
        meta_data {
          color
          add_student_method
          add_student_method_locked
          visited_by_teacher
        }
      }
      ... on RecordNotFoundProblem {
        message
      }
      ... on NotQuiteSureProblem {
        message
      }
      ... on Problem {
        message
      }
    }
  }
`;

// export const CLASS_STUDENTS_QUERY = gql`
//   query classStudents($classId: ID!) {
//     classStudents(classId: $classId) {
//       __typename
//       ... on ClassStudentConnection {
//         edges {
//           node {
//             id
//             f_name
//             l_name
//             email
//             dob
//             last_seen
//             google_id
//             ad_id
//             apple_unique_identifier
//             managed_apple_id
//             external_mis_id
//             device_signature # not available on single records
//             joined # not available on single records
//             image # not available on single records
//             created_at
//             updated_at
//             deleted_at
//             devices {
//               ...DeviceAllParts
//               #    computed {
//               #      ...DeviceComputedParts
//               #    }
//               #    device_active_profile {
//               #      ...DeviceActiveProfileParts
//               #    }
//               # device_uuid
//               # device_signature
//               # device_name
//               # device_name_actual
//               # os_type_id
//               # os_version
//               # os_platform
//               # serial
//               # last_seen
//             }
//             history {
//               type
//               description
//               ts
//               method
//               object_id
//               object_value
//             }
//             grade {
//               ...GradeBaseParts
//             }
//             tags {
//               ...TagBaseParts
//             }
//             school {
//               ...SchoolBaseParts
//             }
//             image
//           }
//         }
//       }
//       ... on NotQuiteSureProblem {
//         message
//       }
//       ... on Problem {
//         message
//       }
//     }
//   }
// `;

export const QUERY_CLASS_CONTENTS = gql`
  query classContents($classId: ID!, $page: Int, $limit: Int, $q: String) {
    classContents(classId: $classId, sortDirection: ASC, limit: $limit, page: $page, q: $q) {
      __typename
      ... on ClassContentConnection {
        edges {
          node {
            id
            name
            description
            object_type
            object_content
            meta_url
            content_type
            meta_data
            immediate
            created_at
            updated_at
            deleted_at
            # class_id
            class {
              id
              name
              description
              teacher {
                id
                firstname
                lastname
                username
              }
              #school {
              #  id
              #  name
              #}
              teachers {
                id
                firstname
                lastname
                username
              }
            }
          }
        }
        pageInfo {
          ...PageInfoAllParts
        }
      }
      ... on NotQuiteSureProblem {
        message
      }
      ... on Problem {
        message
      }
    }
  }
  ${PageInfoFragments.allParts}
`;

export const LIST_CLASS_APPS = gql`
  query classApplications($profileUUID: ID!, $page: Int, $limit: Int) {
    classApplications(profileUUID: $profileUUID, page: $page, limit: $limit) {
      __typename
      ... on ClassApplicationConnection {
        edges {
          node {
            id
            bundle_name
            application_name
            application_os_type_id
            application_id
            icon
          }
        }
        pageInfo {
          ...PageInfoAllParts
        }
      }
      ... on NotQuiteSureProblem {
        message
      }
      ... on Problem {
        message
      }
    }
  }
  ${PageInfoFragments.allParts}
`;

export const LIST_CLASS_APPS_TEACHER_GROUP = gql`
  query classApplicationsTeacherGroup($input: ClassApplicationsInput!) {
    classApplicationsTeacherGroup(input: $input) {
      __typename
      ... on ApplicationConnection {
        edges {
          node {
            id
            name
            bundle_name
            os_type_id
            icon
            auto_install
            self_install
          }
        }
      }
      ... on NotQuiteSureProblem {
        message
      }
      ... on Problem {
        message
      }
    }
  }
`;

export const LIST_CLASS_SESSIONS = gql`
  query classSessions($classId: ID!, $page: Int, $limit: Int, $startDate: String, $endDate: String) {
    classSessions(classId: $classId, page: $page, limit: $limit, startDate: $startDate, endDate: $endDate) {
      __typename
      ... on ClassSessionConnection {
        edges {
          node {
            id
            device_count
            duration
            session_end_time
            created_at
          }
        }

        pageInfo {
          ...PageInfoAllParts
        }
      }
      ... on NotQuiteSureProblem {
        message
      }
      ... on Problem {
        message
      }
    }
  }
  ${PageInfoFragments.allParts}
`;

export const ACTIVE_CLASSES_QUERY = gql`
  query classes($limit: Int!, $page: Int!, $q: String, $filter: ClassListFilter, $sortBy: [SortFieldInput!]) {
    classes(limit: $limit, page: $page, q: $q, filter: $filter, sortBy: $sortBy) {
      __typename
      ... on ClassConnection {
        edges {
          node {
            id
            duration
            screen_share_enabled
            name
            meta_data {
              color
            }
            teacher {
              id
              username
              firstname
              lastname
            }
            active_session {
              id
              created_at
              duration
            }
          }
        }

        pageInfo {
          ...PageInfoAllParts
        }
      }
      ... on NotQuiteSureProblem {
        message
      }
      ... on Problem {
        message
      }
    }
  }
  ${PageInfoFragments.allParts}
`;

export const RECENTLY_ENDED_CLASSES_QUERY = gql`
  query classes($limit: Int!, $page: Int!, $q: String, $filter: ClassListFilter, $sortBy: [SortFieldInput!]) {
    classes(limit: $limit, page: $page, q: $q, filter: $filter, sortBy: $sortBy) {
      __typename
      ... on ClassConnection {
        edges {
          node {
            id
            name
            meta_data {
              color
            }
            last_session {
              id
              session_end_time
            }
          }
        }

        pageInfo {
          ...PageInfoAllParts
        }
      }
      ... on NotQuiteSureProblem {
        message
      }
      ... on Problem {
        message
      }
    }
  }
  ${PageInfoFragments.allParts}
`;

export const INPROGRESS_CLASSES_QUERY = gql`
  query classes($limit: Int!, $page: Int!, $q: String, $filter: ClassListFilter, $sortBy: [SortFieldInput!]) {
    classes(limit: $limit, page: $page, q: $q, filter: $filter, sortBy: $sortBy) {
      __typename
      ... on ClassConnection {
        edges {
          node {
            id
            name
            meta_data {
              color
            }
            active_session {
              id
              created_at
            }
          }
        }

        pageInfo {
          ...PageInfoAllParts
        }
      }
      ... on NotQuiteSureProblem {
        message
      }
      ... on Problem {
        message
      }
    }
  }
  ${PageInfoFragments.allParts}
`;

export const UPCOMING_CLASSES_QUERY = gql`
  query classes($limit: Int!, $page: Int!, $q: String, $filter: ClassListFilter, $sortBy: [SortFieldInput!]) {
    classes(limit: $limit, page: $page, q: $q, filter: $filter, sortBy: $sortBy) {
      __typename
      ... on ClassConnection {
        edges {
          node {
            id
            duration
            screen_share_enabled
            name
            schedules {
              start_time
              end_time
              weekdays
            }
            meta_data {
              color
            }
            teacher {
              id
              username
              firstname
              lastname
            }
          }
        }

        pageInfo {
          ...PageInfoAllParts
        }
      }
      ... on NotQuiteSureProblem {
        message
      }
      ... on Problem {
        message
      }
    }
  }
  ${PageInfoFragments.allParts}
`;

export const REVIEW_CLASSES_QUERY = gql`
  query classes($limit: Int!, $page: Int!, $q: String, $filter: ClassListFilter, $sortBy: [SortFieldInput!]) {
    classes(limit: $limit, page: $page, q: $q, filter: $filter, sortBy: $sortBy) {
      __typename
      ... on ClassConnection {
        edges {
          node {
            id
            name
            schedules {
              start_time
              end_time
              weekdays
            }
            meta_data {
              color
            }
          }
        }

        pageInfo {
          ...PageInfoAllParts
        }
      }
      ... on NotQuiteSureProblem {
        message
      }
      ... on Problem {
        message
      }
    }
  }
  ${PageInfoFragments.allParts}
`;

export const LIST_CLASSES_QUERY = gql`
  query classes($limit: Int!, $page: Int!, $q: String, $filter: ClassListFilter, $sortBy: [SortFieldInput!]) {
    classes(limit: $limit, page: $page, q: $q, filter: $filter, sortBy: $sortBy) {
      __typename
      ... on ClassConnection {
        edges {
          node {
            id
            name
            duration
            description
            base_profile_uuid
            profile_uuid

            created_at
            updated_at
            deleted_at
            last_session_ended
            duration
            force_join
            has_image
            home_url
            in_progress
            meta_data {
              color
              add_student_method
              add_student_method_locked
              visited_by_teacher
            }
            status
            screen_share_enabled
            teacher {
              id
              username
              firstname
              lastname
            }
            teachers {
              id
              firstname
              lastname
              username
              roles {
                id
                name
              }
            }
            school {
              id
              name
            }
            schedules {
              start_time
              end_time
              weekdays
            }
            last_session {
              id
              has_ended
              duration
              pin
              home_url
              created_at
              updated_at
              session_end_time
              allow_raise_hand
            }
            #students {
            #  id
            #  f_name
            #  l_name
            #  grade {
            #    key
            #    value
            #  }
            #  devices {
            #    device_uuid
            #    device_signature
            #    device_name
            #    device_name_actual
            #    os_type_id
            #   os_version
            #    os_platform
            #    serial
            #    last_seen
            #  }
            #}
            active_session {
              id
              # class { }
              has_ended
              duration
              pin
              home_url
              created_at
              updated_at
              session_end_time
              allow_raise_hand
            }
            active_class_mode_session {
              id
              class_mode {
                id
                name
              }
              class_session {
                id
                home_url
                # class { }
              }
              has_ended
              created_at
              updated_at
              ended_at
            }
          }
        }

        pageInfo {
          ...PageInfoAllParts
        }
      }
      ... on NotQuiteSureProblem {
        message
      }
      ... on Problem {
        message
      }
    }
  }
  ${PageInfoFragments.allParts}
`;

export const CLASS_STUDENT_DEVICES = gql`
  query classStudentDevices($classId: ID!, $page: Int, $q: String, $limit: Int) {
    classStudentDevices(classId: $classId, page: $page, q: $q, limit: $limit) {
      __typename
      ... on ClassStudentDeviceConnection {
        edges {
          node {
            session_active
            active_session_joined

            device {
              device_uuid
              device_signature
              device_name
              device_name_actual
              os_type_id
              os_version
              os_platform
              serial
              last_seen
              is_chrome_managed
            }
            student {
              id
              f_name
              l_name
              email
              dob
              last_seen
              google_id
              ad_id
              apple_unique_identifier
              managed_apple_id
              external_mis_id
              device_signature # not available on single records
              joined # not available on single records
              image # not available on single records
              created_at
              updated_at
              deleted_at
              history {
                type
                description
                ts
                method
                object_id
                object_value
              }
              grade {
                key
                value
              }
              # school {
              #   id
              #   name
              #   allow_screen_share_configuration
              # }
              tags {
                key
                value
              }
              #devices {
              #  device_uuid
              #  device_signature
              #  device_name
              #  device_name_actual
              #}
              image
            }
          }
        }
        pageInfo {
          ...PageInfoAllParts
        }
      }
      ... on NotQuiteSureProblem {
        message
      }
      ... on Problem {
        message
      }
    }
  }
  ${PageInfoFragments.allParts}
`;

/**
 * DEPRECATED
 */
// export const QUERY_CLASS_STUDENT_DEVICES_BY_SESSION = gql`
//   query classStudentDevicesBySession($classId: ID!, $sessionId: ID!, $deviceUUID: ID!) {
//     classStudentDevicesBySession(classId: $classId, sessionId: $sessionId, deviceUUID: $deviceUUID) {
//       __typename
//       ... on ClassStudentDeviceConnection {
//         edges {
//           node {
//             session_active
//             active_session_joined
//             device {
//               device_uuid
//               device_signature
//               device_name
//               device_name_actual
//               model
//               product_name
//               jailbroken
//               imei
//               ip_local
//               ip_public
//               mac_wifi
//               mac_bluetooth
//               os_type_id
//               os_version
//               os_platform
//               serial
//               last_seen
//               is_chrome_managed
//               created_at
//               updated_at
//               school_id
//               client_version
//               manufacturer
//               push_token
//               chromebook_android_token
//               is_enrolled
//               is_scep
//               has_wallpaper
//               is_placeholder
//               last_acknowledge
//               last_kick
//               deleted_at
//               cellular_technology
//               is_supervised
//               is_device_locator_service_enabled
//               phone_number
//               is_activation_lock_enabled
//               is_do_not_disturb_in_effect
//               is_roaming
//               personal_hotspot_enabled
//               hardware_encryption_caps
//               passcode_present
//               passcode_compliant
//               passcode_compliant_with_profiles
//               is_dep
//               is_mdm_enroll
//               activation_lock_bypass_code
//               has_mg_app
//               has_mg_webfilter
//               is_lostmode
//               is_app_locked {
//                 id
//                 name
//                 description
//                 bundle_name
//                 icon
//               }
//               max_resident_users
//               google_id
//               need_mdm_refresh
//               mdm_refresh_in_progress
//               is_parent_block_screen
//               is_mirrored
//               user_is_in_device_group
//             }
//             student {
//               id
//               f_name
//               l_name
//               email
//               dob
//               last_seen
//               google_id
//               ad_id
//               apple_unique_identifier
//               managed_apple_id
//               external_mis_id
//               device_signature # not available on single records
//               joined # not available on single records
//               image # not available on single records
//               created_at
//               updated_at
//               deleted_at
//             }
//           }
//         }
//       }
//       ... on NotQuiteSureProblem {
//         message
//       }
//       ... on Problem {
//         message
//       }
//     }
//   }
// `;
