import { authService } from "@/main";
import { NatsService } from "../nats/nats-service";
import screenShareSocket from "../socket/screen-share-socket";
import {
  MUTATION_DEVICE_ACTION_SHARE_SCREEN,
  MUTATION_DEVICE_ACTION_SHARE_TEACHER_SCREEN,
} from "@/app/gql/devices/device_actions_mutations";
import UserUtils from "@/app/utils/users/user_utils";
import { activeClassVar } from "@/app/store/activeClassStore";
import i18next from "i18next";
import { WebRTCConnection } from "./web-rtc-connection";
import { logger } from "../logging/Logger";
import { Subject } from "rxjs";
import { natsSubscriptionHandler } from "../nats/natsSubscriptionHandler";
import logo from "../../style/assets/mg-logo.svg";
import {
  getLocalProtocolTypeForConnection,
  IceCandidatePair_ProtocolTypes,
  ScreenshareStreamProtocol,
} from "@/app/utils/screen-share/screen-share_utils";

const NAMESPACE = "[TeacherWebRTCShare]";
class TeacherWebRTCShare {
  apolloClient;
  sharingDevice;
  currentClassId;
  sharingTo;
  mediaStream;
  video;
  videoStream;
  videoTrack;
  canvas;
  canvasStream;
  canvasTrack;
  teacherScreenShareSubScription;
  subscriptionClosure;
  connections;
  iceCandidates = {};
  remoteSessionDescriptions = {};
  remoteSessionDescriptionSizes = {};
  closeTeacherScreenshareSubscription;
  screenshareStreamProtocol;
  confirmationReceivedFrom;

  constructor(apolloClient) {
    this.apolloClient = apolloClient;
    this.getSharingDevice();
    this.confirmationReceivedFrom = [];
  }

  getSharingDevice() {
    var device = localStorage.getItem("sharing_device");
    if (device && typeof device === "string") {
      this.sharingDevice = { device: JSON.parse(device) };
    }
  }

  _isSharing = new Subject();
  isSharing() {
    return this._isSharing.asObservable();
  }

  async shareMyScreen(devices, classId, protocol) {
    this.screenshareStreamProtocol = protocol;
    this.isRunning = true;
    this.currentClassId = classId;
    if (devices && devices.length > 0) {
      if (this.connections && Object.keys(this.connections).length > 0) {
        let connectionIds = Object.keys(this.connections);
        for (var id of connectionIds) {
          this.closeConnection(this.connections[id]);
        }
        this.connections = {};
      }
      this.sharingTo = devices;
      return this.getMedia()
        .then(async (stream) => {
          this.mediaStream = stream;

          this.mediaStream.oninactive = () => {
            // TODO: we need to stop the screen share
            this.stopSharingMyScreen(this.sharingTo);
          };
          this.mediaStream.onended = () => {
            // TODO: we need to stop the screen share
            this.stopSharingMyScreen(this.sharingTo);
          };
          await this.createVideoStream(this.mediaStream);
          this.listenForMessages();
          await this.startTeacherScreenShare();
          this._isSharing.next(true);
          return true;
        })
        .catch((error) => {
          logger.error(NAMESPACE, "getMedia()", error);
          if (error?.message?.includes("Permission denied")) {
            alert(
              i18next.t(
                "A permission error was thrown trying to initiate Teacher Screenshare. Please ensure browser has necessary permissions to share all screens & windows & try again"
              )
            );
          }
          this._isSharing.next(false);
          return false;
        });
    }
  }

  stopSharingMyScreen(devices) {
    this.isRunning = false;

    this.confirmationReceivedFrom = [];

    if (this.canvasDrawTimer) {
      clearInterval(this.canvasDrawTimer);
    }

    if (this.blankStream) {
      this.blankStream.getTracks().forEach((track) => track.stop());
      this.blankStream = null;
    }

    if (this.video) {
      if (typeof this.video.stop === "function") {
        this.video.stop();
      }
      this.video = null;
    }

    if (this.mediaStream && this.mediaStream.active) {
      this.mediaStream.getTracks().forEach((track) => track.stop());
      this.mediaStream = null;
    }

    if (this.webRTCTimeout) {
      clearInterval(this.webRTCTimeout);
    }
    if (this.connections) {
      let connectionIds = Object.keys(this.connections);
      for (var id of connectionIds) {
        this.closeConnection(this.connections[id]);
      }
      this.connections = {};
      if (devices) {
        authService.ensureMutationIsAuthenticated(() => {
          this.apolloClient
            .mutate({
              mutation: MUTATION_DEVICE_ACTION_SHARE_TEACHER_SCREEN,
              variables: {
                input: {
                  payload: JSON.stringify({
                    action: "stop",
                    module: "share",
                    to: devices?.map((d) => d.device_signature),
                    from: screenShareSocket.socket.id,
                  }),
                  deviceUUID: devices?.map((d) => d.device_uuid),
                },
              },
            })
            .then(
              (data) => {
                logger.debug("gql response:", data);
              },
              (error) => {
                logger.debug("there was an error sending the query", error);
              }
            );
        });
        const schoolId = UserUtils.getCurrentSchoolUser()?.school_id;
        NatsService.getInstance().publish(`school.${schoolId}.class.${this.currentClassId}`, {
          type: "teacher_screen_share",
          targets: devices?.map((d) => d.device_signature),
          payload: JSON.stringify({
            action: "stop",
            module: "share",
            to: devices?.map((d) => d.device_signature),
            from: screenShareSocket.socket.id,
          }),
          deviceUUID: devices?.map((d) => d.device_uuid),
        });
      }
    }
    this.remoteSessionDescriptionSizes = {};
    this.remoteSessionDescriptions = {};
    this.iceCandidates = {};
    this.disposeNatsSubscription();
    this.subscriptionClosure = null;
    this.sharingTo = null;
    this._isSharing.next(false);
    this.deregisterWindowEvents();
  }

  getMedia() {
    if (this.mediaStream && this.mediaStream.active) {
      return Promise.resolve(this.mediaStream);
    }
    return navigator.mediaDevices
      .getDisplayMedia({
        video: {
          width: 1280,
          height: 720,
          frameRate: 1,
        },
        audio: false,
      })
      .then((mediaStream) => {
        return mediaStream;
      })
      .catch((err) => {
        this.mediaStream = null;
        throw err;
      });
  }

  async startTeacherScreenShare() {
    let device_signatures = [];
    let device_uuids = [];
    if (!this.sharingTo && this.sharingTo.length == 0) {
      logger.error(NAMESPACE, "No devices to start sharing your screen with!");
      return;
    }
    this.sharingTo?.map((device) => {
      device_signatures.push(device.device_signature);
      device_uuids.push(device.device_uuid);
    });

    const schoolId = UserUtils.getCurrentSchoolUser()?.school_id;
    NatsService.getInstance().publish(`school.${schoolId}.class.${this.currentClassId}`, {
      type: "teacher_screen_share",
      targets: device_signatures,
      payload: JSON.stringify({
        action: "start",
        module: "share",
        to: device_signatures,
        from: screenShareSocket.socket.id,
        teacherClientId: NatsService.getInstance().teacherSignature,
      }),
      deviceUUID: device_uuids,
    });
  }

  lastMessageId = 0;
  listenForMessages() {
    const schoolId = UserUtils.getCurrentSchoolUser()?.school_id;
    this.currentClassId ??= activeClassVar().classId;
    if (!schoolId || !this.currentClassId) {
      logger.error(NAMESPACE, "School ID or Class ID is null");
      return;
    }

    const channel = `school.${schoolId}.class.${this.currentClassId}.teacher_screen_share`;

    this.closeTeacherScreenshareSubscription = natsSubscriptionHandler(channel, (messageData) => {
      const device = { device_signature: messageData.sender };
      switch (messageData.payload.action) {
        case "start-ack":
          this.createConnection(device);
          break;
        case "answer":
          this.handleAnswer(
            device,
            messageData.payload.answer,
            messageData.payload.has_more,
            messageData.payload.chunk_number
          );
          break;
        case "ice_candidate":
          // TODO: handle ice candidate
          this.handleIceCandidate(device, messageData.payload.iceCandidate);
          break;
        case "stream_confirmation":
          // TODO: replace track with relevant type of stream
          if (this.screenshareStreamProtocol !== ScreenshareStreamProtocol.FORCE_DESKTOP) {
            this.handleStreamConfirmation(device);
          }
          break;
        case "stop":
          return;
      }
    });

    this.registerWindowEvents();
  }

  handleStreamConfirmation(device) {
    const connection = this.connections[device.device_signature];

    this.confirmationReceivedFrom.push(device.device_signature);
    if (connection) {
      const resolution = this.applyBestCanvasResolution();
      this.updateBitrateForConnection(connection, resolution.height / 720, 0);

      const transportProtocol = getLocalProtocolTypeForConnection(connection);
      if (this.screenshareStreamProtocol === ScreenshareStreamProtocol.NETWORK_BASED) {
        if (transportProtocol === IceCandidatePair_ProtocolTypes.HOST) {
          this.replaceTrackForConnection(connection, false, device.device_signature);
        } else {
          this.replaceTrackForConnection(connection, true, device.device_signature);
        }
      }

      if (this.screenshareStreamProtocol === ScreenshareStreamProtocol.FORCE_DESKTOP) {
        this.replaceTrackForConnection(connection, false, device.device_signature);
      }

      if (this.screenshareStreamProtocol === ScreenshareStreamProtocol.FORCE_CANVAS) {
        this.replaceTrackForConnection(connection, true, device.device_signature);
      }
    } else {
      logger.warn(NAMESPACE, "could not find connection for", device.device_signature);
    }
  }

  replaceTrackForConnection(connection, useCanvasTrack, device_signature) {
    logger.debug("1st track replacement", connection?.signature);
    if (useCanvasTrack) {
      connection.getSenders()[0].replaceTrack(this.canvasTrack);
      setTimeout(() => {
        logger.debug("2nd track replacement - canvas", connection?.signature);
        connection.getSenders()[0].replaceTrack(this.canvasTrack);
        this.connections[device_signature] = connection;
      }, 10000);
    } else {
      connection.getSenders()[0].replaceTrack(this.mediaStream.getTracks()[0]);
      this.connections[device_signature] = connection;
    }
  }

  registerWindowEvents() {
    window.onbeforeunload = (event) => {
      logger.warn("window unloading...");
      const message =
        "Are you sure you want to close this window, if you are sharing your screen it will be terminated?";
      event = event || window.event;
      if (event) {
        event.returnValue = message;
      }
      return message;
    };

    window.onunload = (event) => {
      logger.debug("onunload()", event);
      this.stopSharingMyScreen(this.sharingTo);
      this.teacherScreenShareSubScription?.unsubscribe();
      this.teacherScreenShareSubScription = undefined;
    };
  }

  deregisterWindowEvents() {
    window.onbeforeunload = null;
    window.onunload = null;
  }

  async createVideoStream(stream) {
    return new Promise((resolve) => {
      // set up the video element
      this.video = document.createElement("video");
      this.video.srcObject = stream;
      this.video.play();
      this.video.onloadedmetadata = () => {
        if (this.screenshareStreamProtocol !== ScreenshareStreamProtocol.FORCE_DESKTOP) {
          // create canvas
          this.canvas = document.createElement("canvas");
          // create canvas context
          this.canvasContext = this.canvas.getContext("2d");

          this.blankCanvas = document.createElement("canvas");
          this.blankCanvasContext = this.blankCanvas.getContext("2d");
          this.blankCanvasContext.fillStyle = "black";
          this.blankCanvasContext.fillRect(0, 0, this.blankCanvas.width, this.blankCanvas.height);
          this.blankStream = this.blankCanvas.captureStream(1);

          // create stream
          this.canvasStream = this.canvas.captureStream(1);
          this.canvasTrack = this.canvasStream.getTracks()[0];

          this.applyBestCanvasResolution();

          this.initiateCaptureLoop();
        }
        resolve();
      };
    });
  }

  resolutions = {
    highEnd: {
      w: 1280,
      h: 720,
    },
    lowEnd: {
      w: 800,
      h: 600,
    },
  };

  applyBestCanvasResolution() {
    var videoWidth = this.resolutions.highEnd.w;
    var videoHeight = this.resolutions.highEnd.h;

    const vWidth = this.video.videoWidth;
    const vHeight = this.video.videoHeight;
    let widthRatio = vWidth / videoWidth;
    let heightRatio = vHeight / videoHeight;

    var finalWidth = vWidth;
    var finalHeight = vHeight;
    if (videoWidth < vWidth && videoHeight < vHeight) {
      if (widthRatio < heightRatio) {
        finalWidth = videoWidth;
        videoHeight = finalHeight = vHeight / widthRatio;
      } else {
        finalHeight = videoHeight;
        videoWidth = finalWidth = vWidth / heightRatio;
      }
    }
    this.canvas.width = this.blankCanvas.width = finalWidth;
    this.canvas.height = this.blankCanvas.height = finalHeight;

    return {
      width: finalWidth,
      height: finalHeight,
      widthRatio,
      heightRatio,
    };
  }

  initiateCaptureLoop() {
    this.blankCanvasContext?.fillRect(0, 0, this.blankCanvas.width, this.blankCanvas.height);
    this.image = document.createElement("img");
    this.image.src = logo;
    this.image.width = 50;
    this.image.height = 50;

    this.centerHorizontal = this.canvas.width / 2;
    this.centerVertical = this.canvas.height / 2;

    this.canvasDrawTimer = setInterval(() => {
      if (this.isRunning) {
        this.populateBlankStream();

        this.captureDesktopImageOntoCanvas();
      } else {
        clearInterval(this.canvasDrawTimer);
      }
    }, 1000);
  }

  captureDesktopImageOntoCanvas() {
    this.canvasContext?.drawImage(this.video, 0, 0, this.canvas.width, this.canvas.height);
    this.canvasContext?.drawImage(
      this.image,
      this.canvas.width - this.image.width - 10,
      this.canvas.height - this.image.height - 10
    );
  }

  //When using navigator.mediaDevices.getDisplayMedia(), and selecting "Chrome Tab" option, the requestAnimationFrame method does not execute unless the Teacher Dashboard Tab is in focus
  //Populating the blank stream needs to run asynchronously to the requestAnimationFrame() to ensure receiving devices' stream gets populated
  populateBlankStream() {
    let allStreamsPopulated = false;

    if (this.confirmationReceivedFrom?.length === this.sharingTo?.length) {
      //logger.log("[Timer] All streams populated")
      allStreamsPopulated = true;
    } else {
      allStreamsPopulated = false;
    }

    if (this.isRunning) {
      if (!allStreamsPopulated) {
        //logger.log("[Timer] Drawing for blankstream")
        this.blankCanvasContext?.drawImage(this.image, this.centerHorizontal - 25, this.centerVertical - 25, 100, 100);
        this.blankStream?.getVideoTracks()[0].requestFrame();
      }
    }
  }

  getConnection(device) {
    if (!this.connections) {
      this.connections = {};
    }

    let connectionIds = Object.keys(this.connections);
    if (connectionIds.indexOf(device.device_signature) >= 0) {
      let existingConnection = this.connections[device.device_signature];
      if (existingConnection) {
        // close the current connection so we can create a brand new one
        this.closeConnection(existingConnection);
      }
    }
    // create a brand new connection
    var connection = new RTCPeerConnection(WebRTCConnection._configuration);
    connection.signature = device.device_signature;
    connection.onicecandidate = (event) => {
      if (event.candidate != null) {
        this.sendToMultipleDevices(
          [device],
          { action: "ice_candidate", iceCandidate: event.candidate },
          "icecandidate"
        );
      }
    };
    connection.oniceconnectionstatechange = (event) => {
      if (["failed", "disconnected"].includes(event.target.iceConnectionState)) {
        logger.warn(NAMESPACE, "ice connection state changed", event.target.iceConnectionState);
        this.closeConnection(connection);
      }
      if (event.target.iceConnectionState === "connected") {
        logger.debug(NAMESPACE, "ice connected");
      }
    };

    if (this.screenshareStreamProtocol !== ScreenshareStreamProtocol.FORCE_DESKTOP) {
      if (this.blankStream) {
        this.blankStream.getTracks().forEach((track) => {
          connection.addTrack(track, this.blankStream);
        });
      }
    } else {
      this.mediaStream.getTracks().forEach((track) => {
        connection.addTrack(track, this.mediaStream);
      });
    }

    this.connections[device.device_signature] = connection;

    return connection;
  }

  closeConnection(peerConnection) {
    let connectionIds = Object.keys(this.connections);
    if (connectionIds && connectionIds.length > 0) {
      if (connectionIds.includes(peerConnection.signature)) {
        let connection = this.connections[peerConnection.signature];
        if (connection) {
          connection.onicecandidate = null;
          connection.oniceconnectionstatechange = null;
          connection.close();
          // eslint-disable-next-line no-unused-vars
          const { [`${peerConnection.signature}`]: closedConnection, ...rest } = this.connections;
          this.connections = rest;
        }
      }
    }
  }

  createConnection(device) {
    const connection = this.getConnection(device);
    // now we should have a new connection and we can send the offer
    const offerOptions = {
      iceRestart: true,
      offerToReceiveAudio: false,
      offerToReceiveVideo: false,
    };
    connection.createOffer(offerOptions).then((offer) => {
      offer = this.changeBitrate(offer);
      connection.setLocalDescription(offer).then(() => {
        // send offer
        this.sendOffer(device, offer);

        //In the event that a student refreshes their remote-screenshare page AFTER successfully receiving the stream,
        //We need to ensure their stream gets repopulated from blankstream after we send them a new offer (when connection is recreated)
        if (this.confirmationReceivedFrom.indexOf(device?.device_signature) > -1) {
          //Removes device-signature from array of confirmed streams
          this.confirmationReceivedFrom.splice(this.confirmationReceivedFrom.indexOf(device?.device_signature), 1);
        }
      });
    });
  }

  changeBitrate(offer) {
    let sdpParts = offer?.sdp?.split("\r\n");
    let bitratePosition;
    let finalSdp = "";
    if (sdpParts) {
      sdpParts = sdpParts.slice(0, -1);
      for (let index = 0; index < sdpParts.length; index++) {
        const element = sdpParts[index];
        if (element.match(/b=AS:/)) {
          bitratePosition = index;
        }
      }

      if (bitratePosition) {
        sdpParts.splice(bitratePosition, 1);
      }

      var bitrate = 0; // 400 * 1000;

      for (let index = 0; index < sdpParts.length; index++) {
        if (sdpParts[index].match(/m=video/) && bitrate > 0) {
          //modify and add the new lines for video
          finalSdp += sdpParts[index] + "\r\n" + "b=AS:" + bitrate + "\r\n";
        } else {
          finalSdp += sdpParts[index] + "\r\n";
        }
      }

      offer.sdp = finalSdp;
      return offer;
    }
    return offer;
  }

  updateBitrate(bandwidth, ratio = 1) {
    if (this.connections) {
      const connectionIds = Object.keys(this.connections);
      for (var id of connectionIds) {
        const sender = this.connections[id].getSenders()[0];
        this.updateBitrateForConnection(sender, ratio, bandwidth);
      }
    }
  }

  updateBitrateForConnection(connection, ratio = 1, bandwidth) {
    try {
      const [sender] = connection.getSenders();
      const params = sender.getParameters();
      if (!params.encodings) {
        params.encodings = [{}];
      }
      if (bandwidth == 0) {
        delete params.encodings[0].maxBitrate;
      } else {
        logger.debug(NAMESPACE, "updating bit rate for", connection.signature, "to", bandwidth * 1000);
        params.encodings[0].maxBitrate = bandwidth * 1000;
      }
      logger.debug(NAMESPACE, "ratio", ratio);
      params.scaleResolutionDownBy = Math.max(ratio, 1);
      sender.setParameters(params);
    } catch (e) {
      logger.error(NAMESPACE, e);
    }
  }

  handleAnswer(device, answer, hasMore, chunkOrder) {
    if (!answer) {
      return;
    }
    let sdp = answer.sdp;
    let device_signature = device.device_signature;

    if (!this.remoteSessionDescriptions[device_signature]) {
      this.remoteSessionDescriptions[device_signature] = [];
    }
    let remoteSession = this.remoteSessionDescriptions[device_signature];

    remoteSession[chunkOrder] = sdp;
    if (!hasMore) {
      this.remoteSessionDescriptionSizes[device_signature] = chunkOrder + 1;
    }
    let len = Object.keys(remoteSession).length;
    if (!hasMore && len !== this.remoteSessionDescriptionSizes[device_signature]) {
      return;
    }

    if (this.remoteSessionDescriptionSizes[device_signature] !== len) {
      return;
    }

    answer.sdp = remoteSession.reduce((obj, str) => {
      obj += str;
      return obj;
    }, "");

    const connection = this.connections[device_signature];

    if (connection && connection.connectionState != "closed") {
      if (["stable", "closed"].includes(connection.signalingState)) {
        logger.warn(NAMESPACE, `signaling state ${connection.signalingState} is invalid!`);
        this.closeConnection(connection);
        return;
      }
      connection
        .setRemoteDescription(answer)
        .then(() => {
          if (this.iceCandidates && this.iceCandidates[device.device_signature]) {
            let iceCandidates = this.iceCandidates[device.device_signature];
            iceCandidates.forEach((ice) => {
              connection.addIceCandidate(ice);
            });
          }
        })
        .catch((error) => {
          logger.error(NAMESPACE, device.device_signature, error);
          this.closeConnection(connection);
        })
        .finally(() => {
          this.iceCandidates[device.device_signature] = [];
          this.remoteSessionDescriptions[device.device_signature] = [];
          this.remoteSessionDescriptionSizes[device.device_signature] = [];
        });
    }
  }

  handleIceCandidate(device, iceCandidate) {
    const connection = this.connections[device.device_signature];
    if (
      !connection ||
      !(connection.remoteDescription && connection.remoteDescription.type) ||
      ["stable", "closed"].includes(connection.signalingState)
    ) {
      if (!this.iceCandidates[device.device_signature]) {
        this.iceCandidates[device.device_signature] = [];
      }
      this.iceCandidates[device.device_signature].push(iceCandidate);
    } else {
      connection.addIceCandidate(iceCandidate);
    }
  }

  sendOffer(device, offer) {
    screenShareSocket.socket.emit(screenShareSocket.SCREEN_SHARE_SEND, {
      to: device.device_signature,
      from: screenShareSocket.socket.id,
      class: this.currentClassId,
      payload: {
        module: "share",
        action: "offer",
        offer: {
          type: offer.type,
          sdp: offer.sdp,
        },
        has_more: 0,
        chunk_number: 0,
        to: device.device_signature,
        from: screenShareSocket.socket.id,
      },
    });
    const schoolId = UserUtils.getCurrentSchoolUser()?.school_id;
    this.currentClassId ??= activeClassVar().classId;
    if (!schoolId || !this.currentClassId) {
      logger.error(NAMESPACE, "sendOffer()", "School ID or Class ID is null");
      return;
    }
    NatsService.getInstance().publish(`school.${schoolId}.class.${this.currentClassId}.teacher_screen_share`, {
      type: "teacher_screen_share",
      targets: [device.device_signature],
      payload: {
        action: "offer",
        offer: {
          type: offer.type,
          sdp: offer.sdp,
        },
        has_more: 0,
        chunk_number: 0,
      },
    });
  }

  sendToMultipleDevices(devices, data, action = "", socketDisabled = false) {
    let device_signatures = [];
    let device_uuids = [];

    devices?.map((device) => {
      device_signatures.push(device.device_signature);
      device_uuids.push(device.device_uuid);
    });

    let payload = {
      ...data,
      action,
      module: "share",
      to: "",
      from: screenShareSocket.socket.id,
    };

    if (screenShareSocket.connected && !socketDisabled) {
      devices?.map((device) => {
        payload.to = device.device_signature;
        screenShareSocket.socket.emit(screenShareSocket.SCREEN_SHARE_SEND, {
          to: device.device_signature,
          from: screenShareSocket.socket.id,
          class: this.currentClassId,
          payload: payload,
        });
      });
    } else {
      authService.ensureMutationIsAuthenticated(() => {
        this.apolloClient
          .mutate({
            mutation: MUTATION_DEVICE_ACTION_SHARE_SCREEN,
            variables: {
              input: {
                //payload: JSON.stringify(payload),
                on: payload.on,
                to: device_signatures,
                from: screenShareSocket.socket.id,
                deviceUUIDs: device_uuids,
              },
            },
          })
          .then(
            (data) => {
              logger.debug("gql response:", data);
            },
            (error) => {
              logger.debug("there was an error sending the query", error);
            }
          );
      });
    }

    const schoolId = UserUtils.getCurrentSchoolUser()?.school_id;
    const classId = activeClassVar().classId;
    if (!schoolId || !classId) {
      logger.error(NAMESPACE, "sendToDevice", "School ID or Class ID is null");
      return;
    }

    NatsService.getInstance().publish(`school.${schoolId}.class.${classId}.teacher_screen_share`, {
      type: "teacher_screen_share",
      targets: device_signatures ? device_signatures : [],
      payload: data,
    });
  }

  disposeNatsSubscription() {
    if (this.closeTeacherScreenshareSubscription && typeof this.closeTeacherScreenshareSubscription === "function")
      this.closeTeacherScreenshareSubscription();
  }
}

export default (apolloClient) => {
  return new TeacherWebRTCShare(apolloClient);
};
